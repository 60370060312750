<template>
  <entity-form
    :id="announcement ? announcement.id : null"
    module-name="announcementsList"
    :model="form"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <b-form-row>
      <b-col
        md="8"
        xs="12"
      >
        <b-form-group>
          <label>Title</label>
          <b-form-input
            v-model="form.title"
            type="text"
            placeholder="Title"
            required
          />
        </b-form-group>
      </b-col>
      <b-col
        md="4"
        xs="12"
        class="justify-content-center"
      />
      <b-form-checkbox
        v-model="form.active"
        class="custom-control-primary"
      >
        Activate announcement
      </b-form-checkbox>
    </b-form-row>

    <b-form-row>
      <b-col
        md="6"
        xs="12"
      >
        <label>Start datetime</label>
        <flat-pickr
          v-model="form.start_datetime"
          class="form-control"
          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
        />
      </b-col>

      <b-col
        md="6"
        xs="12"
      >
        <label>End datetime</label>
        <flat-pickr
          v-model="form.end_datetime"
          class="form-control"
          :config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
        />
      </b-col>
    </b-form-row>
    <b-form-row class="mt-1">
      <label>Content</label>
      <b-form-textarea
        v-model="form.content"
        placeholder="Content"
        rows="8"
      />
    </b-form-row>

    <b-form-row class="mt-1">
      <label>Style</label>
      <b-form-input
        v-model="form.style"
        placeholder="Style"
      />
      <b-badge
        v-for="(option, index) in options"
        :key="index"
        :variant="option"
        size="xl"
        class="ml-1 mt-1 p-1 btn-light"
        @click="form.style = option"
      >
        <strong>{{ option }}</strong>
      </b-badge>
    </b-form-row>

  </entity-form>
</template>
<script>
import {
  BFormGroup, BFormInput, BCol, BFormRow,
  BFormCheckbox, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import FlatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { formatCurrency } from '@/plugins/formaters'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

const options = [
  'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark',
]

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRow,
    BCol,
    FlatPickr,
    BFormTextarea,
    BBadge,
    EntityForm,
  },
  directives: {
    Ripple,
  },
  props: {
    announcement: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      form: {
        title: this.announcement ? this.announcement.title : '',
        content: this.announcement ? this.announcement.content : '',
        style: this.announcement ? this.announcement.style : '',
        start_datetime: this.announcement ? this.announcement.start_datetime : null,
        end_datetime: this.announcement ? this.announcement.end_datetime : null,
        active: this.announcement ? this.announcement.active : false,
      },
      options,
    }
  },
  methods: {
    formatCurrency,
    beforeSubmit() {
      this.form.start_datetime = this.form.start_datetime === null ? null : new Date(this.form.start_datetime).toISOString()
      this.form.end_datetime = this.form.end_datetime === null ? null : new Date(this.form.end_datetime).toISOString()

      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
