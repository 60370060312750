<template>
  <div>
    <b-row class="justify-content-between mb-2">
      <b-col lg="6">
        <b-form-group
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="advancedFilter.search"
              type="search"
              placeholder="Search by title"
            />
            <b-input-group-append>
              <b-button
                :disabled="!advancedFilter.search"
                @click="advancedFilter.search = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        lg="6"
        class="text-center"
      >
        <b-button
          variant="primary"
          @click="onCreateAnnouncement"
        >
          Create announcement
        </b-button>
      </b-col>
    </b-row>

    <data-table
      :module-name="'announcementsList'"
      :fields="fields"
      :filters="advancedFilter"
      sort-by="createdAt"
      :sort-desc="false"
      :actions="actions"
      deletable
      @edit="onEdit"
    >
      <template #cell(show_details)="row">
        <show-details :row="row" />
      </template>

      <template #row-details="row">
        <b-row>
          <b-col
            lg="2"
            class="mt-1 ml-1"
          >
            Content:
          </b-col>
          <b-col
            lg="8"
            class="mt-1 mr-1"
          >
            {{ row.item.content }}
          </b-col>
        </b-row>
      </template>

      <template #cell(name)="data">
        {{ data.item.title }}
      </template>

      <template #cell(start_datetime)="data">
        {{ formatDateTime(data.item.start_datetime) }}
      </template>

      <template #cell(end_datetime)="data">
        {{ formatDateTime(data.item.end_datetime) }}
      </template>

      <template #cell(style)="data">
        <b-badge :variant="data.item.style">
          {{ data.item.style }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(state)="data">
        <b-badge :variant="data.item.active ? 'primary' : 'danger'">
          {{ data.item.active ? 'active' : 'disabled' }}
        </b-badge>
      </template>

    </data-table>
    <b-modal
      id="announcement-modal"
      :title="title"
      :no-close-on-backdrop="true"
      size="lg"
      hide-footer
    >
      <announcement-form
        :announcement="announcementEdit"
        @submit="onModalSubmit"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BRow, BCol, BButton, BInputGroup, BInputGroupAppend, BFormInput, BFormGroup, BBadge,
} from 'bootstrap-vue'
import { get } from 'vuex-pathify'
import DataTable from '@/components/ui/tables/DataTable.vue'
import table from '@/mixins/table'
import { formatDateTime } from '@/plugins/formaters'
import AnnouncementForm from '@/components/forms/announcement/AnnouncementForm.vue'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
]

export default {
  components: {
    AnnouncementForm,
    DataTable,
    ShowDetails,
    BRow,
    BCol,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    BBadge,
  },
  mixins: [table],
  data() {
    return {
      fields: [
        'show_details',
        {
          key: 'title',
          sortable: true,
        },
        {
          key: 'start_datetime',
          sortable: true,
        },
        {
          key: 'end_datetime',
          sortable: true,
        },
        {
          key: 'style',
          sortable: true,
        },
        {
          key: 'createdAt',
          sortable: true,
        },
        {
          key: 'state',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      filterOn: ['title'],
      filter: null,
      advancedFilter: { search: '' },
      actions,
      announcementEdit: null,
      title: '',
    }
  },
  computed: {
    ...get('announcementsList', ['data']),
  },
  methods: {
    formatDateTime,
    onCreateAnnouncement() {
      this.announcementEdit = null
      this.title = 'Create announcement'
      this.$bvModal.show('announcement-modal')
    },
    onEdit(item) {
      this.announcementEdit = item
      this.title = 'Edit announcement'
      this.$bvModal.show('announcement-modal')
    },
    onModalSubmit() {
      this.$bvModal.hide('announcement-modal')
    },
  },
}
</script>
